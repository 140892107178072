import React, { useState } from 'react'
import CookiePopUp from './CookiePopUp'
import I18nContext from '../lib/i18n-context'
import cn from '../locales/cn'
import en from '../locales/en'
import replaceFontFamilies from '../lib/replace-font-families'
import $ from 'jquery'

export default ({ children }) => {
    const currentLocale =
        (typeof window !== 'undefined' && window.localStorage.getItem('locale')) || 'en'

    const [locale, setLocale] = useState(en)

    const setCurrentLocale = (locale) => {
        return localStorage.setItem('locale', locale)
    }

    React.useEffect(() => {
        $.getJSON('https://ipapi.co/json/', function(data) {
            if (data.country === 'CN') {
                setCurrentLocale('cn')
            }
        })

        const activeLocale = localStorage.getItem('locale') || 'en'

        if (activeLocale === 'en') {
            localStorage.setItem('locale', 'en')
            setLocale(en)
        } else if (activeLocale === 'cn') {
            localStorage.setItem('locale', 'cn')
            setLocale(cn)
            replaceFontFamilies(activeLocale)
        }

        setCurrentLocale(activeLocale)
    }, [])

    return (
        <I18nContext.Provider
            value={{
                locale: locale,
                currentLocale: currentLocale,
                setCurrentLocale: setCurrentLocale,
            }}
        >
            <div className="w-full overflow-hidden">
                {children}
                <CookiePopUp />
            </div>
        </I18nContext.Provider>
    )
}
