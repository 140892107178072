import React from 'react'

export default {
    appHeader: {
        buttonTitle: '菜单',
        menu: [
            {
                title: '我们的服务',
                link: '/our-services',
            },
            {
                title: '我们的领域',
                link: '/our-reach',
            },
            {
                title: '我们的故事',
                link: '/about-us',
            },
            {
                title: '我们的案例',
                link: '/our-work',
            },
            {
                title: '我们的理念',
                link: '/ideas',
            },
        ],
        contactUs: '联系我们',
    },
    homeBanner: {
        title: (
            <div
                dangerouslySetInnerHTML={{
                    __html: '您进入欧洲市场的途径',
                }}
            />
        ),
        buttonTitle: '现在观看',
        slogan: '我们打造国际化品牌',
    },
    aboutUs: {
        title: '我们的故事',
        subTitle: (
            <h5
                className="text-md sm:text-sm md:text-md lg:text-xl xl:text-2xl"
                dangerouslySetInnerHTML={{
                    __html: '品牌所有者经常面临这样一个问题：在欧洲市场发展的正确途径是什么',
                }}
            />
        ),
        description:
            '无论是进驻新市场、管理销售渠道还是推出新产品系列，我们都为我们的品牌客户提供针对性的定制解决方案，以加速增长和创造价值。',
        buttonTitle: '了解更多',
    },
    ourWork: {
        title: '我们的案例',
        description: '我们将卓越的运营与一丝不苟聚焦品牌增长的意识相结合，去打造行业领导者。',
        projectOne: {
            title: '女性补铁',
            description: (
                <div
                    dangerouslySetInnerHTML={{
                        __html:
                            '<span class="english-font font-arima">Floradix</span> (铁元) 如何通过营销与销售紧密联动而成为补铁保健品类目的销售冠军',
                    }}
                />
            ),
        },
        projectTwo: {
            title: '零食新主张',
            description: (
                <div
                    dangerouslySetInnerHTML={{
                        __html:
                            '<span class="english-font font-arima">Grenade</span> (燃拓) 如何通过扩大营销和销售触达范围来解决在中国市场品牌认知度低的问题',
                    }}
                />
            ),
        },
        projectThree: {
            title: (
                <div
                    dangerouslySetInnerHTML={{
                        __html: '把<span class="english-font font-arima">Nando\'s</span>带回家',
                    }}
                />
            ),
            description: (
                <div
                    dangerouslySetInnerHTML={{
                        __html:
                            '<span class="english-font font-arima">Nando\'s</span> (南逗) 如何利用品牌情怀加速推动其在中国的零售业务',
                    }}
                />
            ),
        },
        buttonTitle: '了解更多',
    },
    whyUs: {
        title: '为什么选择我们？',
        subTitle: (
            <div
                dangerouslySetInnerHTML={{
                    __html:
                        '我们是<span class="english-font font-arima">QIVA</span>，一个屡获殊荣的全球化国际品牌运营合作伙伴',
                }}
            />
        ),
        brand: {
            title: '品牌',
            features: [
                {
                    title: '成果',
                    description: '我们用客户业绩为自己背书。',
                },
                {
                    title: '营销能力',
                    description: '我们是技巧与流程的专家，这为成功的品牌建设奠定了基础。',
                },
                {
                    title: '营销方法',
                    description:
                        '我们以品牌长期发展愿景为指导，为客户量身定制可量化追踪的解决方案。',
                },
            ],
        },
        operation: {
            title: '运营',
            features: [
                {
                    title: (
                        <div
                            dangerouslySetInnerHTML={{
                                __html:
                                    '端对端 <span class="english-font font-arima">(End-to-end)</span> & 全渠道 <span class="english-font font-arima">(Omni-channel)</span>',
                            }}
                        />
                    ),
                    description: '我我们为您在欧洲市场提供电商、实体店铺和新零售渠道的无缝服务。',
                },
                {
                    title: '卓越的电商运营',
                    description: '我们是五星认证的电商运营服务商。',
                },
                {
                    title: '数据驱动',
                    description: '我们监控和分析数据，用客观数据指导和优化运营。',
                },
            ],
        },
        partner: {
            title: '伙伴',
            features: [
                {
                    title: '长远视野',
                    description: '我们建立品牌，让品牌对我们所处时代的重要方面产生影响力。',
                },
                {
                    title: '全球视角',
                    description: '我们运用多元化的思维和多语言能力为客户提高效益。',
                },
                {
                    title: '团队协作',
                    description: '我们与客户优势互补，共同克服复杂的问题。',
                },
            ],
        },
    },
    ourModel: {
        title: '我们的模式',
        subTitle: (
            <div
                dangerouslySetInnerHTML={{
                    __html:
                        '我们的端对端解决方案 <span class="english-font font-arima">(end-to-end solution)</span> 通过“小数据”进行相互关联和操作；',
                }}
            />
        ),
        description: '我们使用它为您的品牌在欧洲市场提供定制解决方案。',
        buttonTitle: '我们的服务',
    },
    ourClients: {
        title: '我们的客户',
    },
    ideas: {
        title: '我们的理念',
        description:
            '创新需要可执行并具有差异化---那种可以激发消费者的兴趣, 同时将新类目和新兴市场带入现实的想法。',
    },
    quotes: {
        title: '来自客户的评价……',
        testimonials: [
            {
                quote: (
                    <div
                        dangerouslySetInnerHTML={{
                            __html:
                                '<span class="english-font font-arima">“As brand owners, we look for global solutions that meet local market needs. We want to work with one partner that delivers consistency and consistently…and that\'s exactly what we get with QIVA Global”</span> <br /><br /> “作为品牌持有者，我们寻求能满足本地市场需求的全球化解决方案。我们希望能够与一个可以提供一致性方案的合作伙伴合作，而这正是<span class="english-font font-arima">QIVA Global</span>可以做到的。”',
                        }}
                    />
                ),
                role: '国际销售主管',
                company: (
                    <div
                        dangerouslySetInnerHTML={{
                            __html: '<span class="english-font font-arima">Grenade</span> [燃拓]',
                        }}
                    />
                ),
            },
            {
                quote: (
                    <div
                        dangerouslySetInnerHTML={{
                            __html:
                                '<span class="english-font font-arima">“We have seen measurable impact across sales and marketing metrics since starting to work with QIVA. As a brand, we are all about working globally and acting locally. With QIVA as our partner, we feel really confident in our local execution”</span> <br /><br /> “自从与QIVA合作以来，我们已经看到了销售和营销上巨大的成功。作为一个品牌，我们致力于制订全球化策略和在细分本地市场营销。有了<span class="english-font font-arima">QIVA</span>作为我们的合作伙伴，我们对我们的本地执行非常有信心。”',
                        }}
                    />
                ),
                role: '国际市场主管',
                company: (
                    <div
                        dangerouslySetInnerHTML={{
                            __html: '<span class="english-font font-arima">Nando\'s</span> [南逗]',
                        }}
                    />
                ),
            },
            {
                quote: (
                    <div
                        dangerouslySetInnerHTML={{
                            __html:
                                '<span class="english-font font-arima">“Working with the QIVA team has been invaluable to our business. Together we have taken Bjorg from new entrant to full market, omni-channel coverage within a one-year period. Our brand is experiencing massive growth in China and we\'re really excited about what\'s to come next”</span> <br /><br /> “与<span class="english-font font-arima">QIVA</span>团队合作对我们的业务发展至关重要。我们一起合作的第一年内，倍优禾从一个中国市场的新入局者转变为全市场、全渠道覆盖的成熟品牌。我们的品牌在中国正经历着巨大的增长，我们对下一步的发展也感到非常期待。”',
                        }}
                    />
                ),
                role: '国际销售主管',
                company: (
                    <div
                        dangerouslySetInnerHTML={{
                            __html:
                                '<span class="english-font font-arima">Ecotone (Bjorg)</span> [倍优禾]',
                        }}
                    />
                ),
            },
            {
                quote: (
                    <div
                        dangerouslySetInnerHTML={{
                            __html:
                                '<span class="english-font font-arima">“Working with QIVA\'s marketing team, we have developed a great relationship with our consumers. Over 4 months, the number of store visitors coming to us through search has increased 280%”</span> <br /><br /> “和<span class="english-font font-arima">QIVA</span>的营销团队合作之后，我们和消费者之间建立了非常亲密的关系。<span class="english-font font-arima">4</span>个月内通过搜索的进店访客数量增长了<span class="english-font font-arima">280</span>%。”',
                        }}
                    />
                ),
                role: '亚洲市场营销主管',
                company: (
                    <div
                        dangerouslySetInnerHTML={{
                            __html: '<span class="english-font font-arima">Nando\'s</span> [南逗]',
                        }}
                    />
                ),
            },
            {
                quote: (
                    <div
                        dangerouslySetInnerHTML={{
                            __html:
                                '<span class="english-font font-arima">“QIVA\'s unique marketing approach has increased our brand search 1500% on Tmall, driving huge growth in our protein bar range”</span> <br /><br /> “<span class="english-font font-arima">QIVA</span>独特的营销方法使我们在天猫平台的品牌搜索量增加了<span class="english-font font-arima">1500</span>%，推动了我们蛋白棒系列销售额的巨大增长。”',
                        }}
                    />
                ),
                role: '中国区主管',
                company: (
                    <div
                        dangerouslySetInnerHTML={{
                            __html: '<span class="english-font font-arima">Grenade</span> [燃拓]',
                        }}
                    />
                ),
            },
            {
                quote: (
                    <div
                        dangerouslySetInnerHTML={{
                            __html:
                                '<span class="english-font font-arima">“The QIVA team have provided us with the clarity and confidence we need to take our brand into new Asian markets.”</span> <br /><br /> “<span class="english-font font-arima">QIVA</span>团队为我们提供了将品牌带入亚洲新市场所需的清晰性和自信心。”',
                        }}
                    />
                ),
                role: '销售主管',
                company: (
                    <div
                        dangerouslySetInnerHTML={{
                            __html: '<span class="english-font font-arima">Edgard & Cooper</span>',
                        }}
                    />
                ),
            },
            {
                quote: (
                    <div
                        dangerouslySetInnerHTML={{
                            __html:
                                '<span class="english-font font-arima">“QIVA are our trusted partners leading our growth and have been nothing but a pleasure to deal with from the first instance. Professional and extremely brand focused”</span> <br /><br /> “<span class="english-font font-arima">QIVA</span>是我们值得信赖的合作伙伴，一直引领着我们发展，从一开始我们和<span class="english-font font-arima">QIVA</span>的合作就很愉快，他们十分专业且以品牌利益为出发点。”',
                        }}
                    />
                ),
                role: 'CEO',
                company: (
                    <div
                        dangerouslySetInnerHTML={{
                            __html: '<span class="english-font font-arima">BeyondNRG</span>',
                        }}
                    />
                ),
            },
            {
                quote: (
                    <div
                        dangerouslySetInnerHTML={{
                            __html:
                                '<span class="english-font font-arima">“As a luxury brand we needed a partner who understood our value proposition and could operationalise it. Working with QIVA has been a great experience”</span> <br /><br /> “作为一个奢侈品牌，我们需要一个了解我们的价值主张并能够实施的合作伙伴。与 <span class="english-font font-arima">QIVA</span> 合作是一次很棒的经历。”',
                        }}
                    />
                ),
                role: '亚洲市场主管',
                company: (
                    <div
                        dangerouslySetInnerHTML={{
                            __html: '<span class="english-font font-arima">vVardis</span>',
                        }}
                    />
                ),
            },
            {
                quote: (
                    <div
                        dangerouslySetInnerHTML={{
                            __html:
                                '<span class="english-font font-arima">"Salus commissioned QIVA to implement a plan to increase consumer demand for the Floradix brand. Working with QIVA, we have strengthened our capability and the results have been fantastic. It\'s a great pleasure working with Ellie and team. We can absolutely recommend their services"</span> <br /><br /> “<span class="english-font font-arima">Salus</span>委托QIVA制定一份营销策划，以刺激消费者对Floradix铁元品牌的需求增长。与<span class="english-font font-arima">QIVA</span>合作，我们提高了我们的运营能力，结果也是非常出色。很高兴与Ellie和<span class="english-font font-arima">QIVA</span>团队合作，我们绝对推荐他们的服务。”',
                        }}
                    />
                ),
                role: '亚太和大中华地区主管',
                company: (
                    <div
                        dangerouslySetInnerHTML={{
                            __html:
                                '<span class="english-font font-arima">Salus</span> (<span class="english-font font-arima">Floradix</span>铁元)',
                        }}
                    />
                ),
            },
        ],
    },
    featuredIn: {
        title: '资质荣誉',
    },
    contactUs: {
        title: '联系我们',
        uk: '英国',
        asia: '亚洲',
        china: '中国',
        japan: '日本',
    },
    callToAction: {
        title: '您在欧洲的品牌运营合作伙伴',
        description: '在领英上关注我们',
        buttonTitle: '关注我们',
    },
    footer: {
        privacy: '隐私政策',
        contact: '联系我们',
    },
    ourServicesBanner: {
        title: '我们的一站式解决方案',
        description: (
            <div
                className="text-md sm:text-sm lg:text-md font-poppins space-y-5"
                dangerouslySetInnerHTML={{
                    __html:
                        '<p> 我们通过涵盖品牌发展生命周期的一站式全案服务，为您的品牌提供从概念到市场执行的全方位支持。 </p><p> 我们的服务独一无二，由技术精湛的市场专家团队为您提供在欧洲市场全面有效的品牌管理。</p><p> 量身定制和深思熟虑，您可以选择您需要的精准的专业知识来实施您的版图战略并实现品牌盈利的雄心壮志。 </p>',
                }}
            />
        ),
    },
    features: {
        strategy: {
            type: '战略',
            title: '种子',
            subHeading: '为进驻欧洲市场和品牌成长做好准备',
            firstPara:
                '从进驻欧洲市场到运营战略，我们的数据方法能帮助品牌理解目标人群并自信地驾驭欧洲市场。',
            secondPara:
                '我们的运作模式以定量方法以及与零售商、分销商和技术平台的实地关系为基础，使我们的客户能够做出明确、及时和精准的选择。',
            features: [
                {
                    id: '1',
                    title: '机遇评估',
                },
                {
                    id: '2',
                    title: '市场进驻策略',
                },
                {
                    id: '3',
                    title: '跨境',
                },
                {
                    id: '4',
                    title: '电商策略',
                },
                {
                    id: '5',
                    title: '运营策略',
                },
            ],
        },
        brand: {
            type: '品建',
            title: '种草',
            subHeading: '创造共鸣和信任',
            firstPara:
                '产生影响力是激发消费者信任您的品牌的关键，无论是通过“always on”营销、达人引导、创意设计还是进行有效公关的方式。',
            secondPara:
                '我们通过与目标客群和销售平台的实时连接来优化品牌建设。这种方法可以实现对目标受众的取证理解，个性化消息流传递和创新交付。',
            features: [
                {
                    id: '1',
                    title: '社交媒体运营',
                },
                {
                    id: '2',
                    title: '传播策略',
                },
                {
                    id: '3',
                    title: '设计',
                },
                {
                    id: '4',
                    title: '官号营销',
                },
                {
                    id: '5',
                    title: '非官号营销 (达人)',
                },
            ],
        },
        sales: {
            type: '销售',
            title: '割草',
            subHeading: '将信任转化为购买决策',
            firstPara:
                '我们帮助品牌在动态的、融入技术并与社交集成的欧洲市场环境中进行韧性销售，无论是电商平台还是实体零售等各个领域我们都这样操作。',
            secondPara:
                '我们的运作模式包括创建针对您的市场机遇而优化的销售渠道，集成营销与销售，并由全程物流服务提供支持。',
            features: [
                {
                    id: '1',
                    title: (
                        <div
                            dangerouslySetInnerHTML={{
                                __html:
                                    '电商店铺管理 / 代运营 / <span class="english-font font-poppins">D2C</span>',
                            }}
                        />
                    ),
                },
                {
                    id: '2',
                    title: (
                      <div
                          dangerouslySetInnerHTML={{
                              __html:
                                  '新零售 <span class="english-font font-poppins">(O2O)</span>',
                          }}
                      />
                  ),
                },
                {
                    id: '3',
                    title: '直播',
                },
                {
                    id: '4',
                    title: '社交电商',
                },
            ],
        },
    },
    howItWorks: {
        title: '这一切如何协同工作',
        description:
            '我们用“小数据”来帮助您了解您在欧洲的目标客群。我们帮助您在正确的时间用正确的方式传递正确的信息给正确的人，将目标客群的兴趣转化为购买决策。这个过程永远不会停止——您的目标客群不是一成不变的，您也应该保持动态跟随。',
    },
    ourServicesInAction: {
        title: '参考我们正在执行的案例',
    },
    ourReachBanner: {
        title: '我们的领域',
        description: '与QIVA合作您会收获什么？',
    },
    ourReachTestimonial: {
        quote: (
            <div
                dangerouslySetInnerHTML={{
                    __html:
                        '<p>“作为品牌持有者，我们寻求能满足 <strong>本地市场</strong> 需求的<strong>全球化</strong/>解决方案。我们希望能够与一个可以提供一<strong>致性</strong>方案的合作伙伴合作，而这正是QIVA Global可以做到的。”</p>',
                }}
            />
        ),
        role: '国际销售主管',
        company: '燃拓',
    },
    ourReachStatement: {
        description:
            '我们与大型跨国公司合作来推广其追求高增长的挑战者品牌，目的是实现它在全球市场上的版图规划以及可持续的增长。',
    },
    ourReachBrands: {
        title: '我们为客户提供以下通道：',
        keyFacts: [
            {
                number: '9',
                title: '大核心市场',
                description:
                    '(亚洲：中国、日本、韩国、马来西亚、新加坡、 欧洲：英国、德国、奥地利、瑞士)',
            },
            {
                number: '50+',
                title: '经销商',
            },
            {
                number: '120+',
                title: '主要连锁零售商',
            },
            {
                number: '30+',
                title: '主流电商平台',
            },
            {
                number: '40+',
                title: '主流社交媒体渠道',
            },
        ],
    },
    ourReachStats: {
        title: '我们广泛地覆盖和渗透到欧洲和亚洲的各销售渠道',
        description:
            '我们的多渠道方式意味着您可以与更广泛的消费者互动，从而最大限度地提高销售额并为您的品牌创造新机遇。',
        quote: '“我们从一个新的市场进入者发展到在像Olé这样的精品超市中占有一席之地。”',
        company: (
            <div
                dangerouslySetInnerHTML={{
                    __html: '<span class="english-font font-arima">Ecotone (Bjorg)</span>',
                }}
            />
        ),
    },
    whatWeDo: {
        title: '我们做什么',
        buttonTitle: '了解更多',
        services: '我们的服务',
        ideas: '我们的理念',
        work: '我们的案例',
        aboutUs: '关于我们',
    },
    aboutUsBanner: {
        title: '我们的故事',
        description: (
            <div
                className="text-md sm:text-sm lg:text-md xl:text-lg font-poppins space-y-4 lg:space-y-6"
                dangerouslySetInnerHTML={{
                    __html:
                        '<p> 我们是消费品牌运营合作伙伴，我们与这个时代最具活力的消费品牌合作。 </p> <p> 我们在这里帮助一个品牌从不知名的局外者成长为欧洲市场的领导者，创造市场影响力和表现力的新标准。</p>',
                }}
            />
        ),
    },
    aboutUsStatement: {
        statement: (
            <div
                dangerouslySetInnerHTML={{
                    __html:
                        '我们成立于<span class="english-font font-arima">2016</span>年，我们的旅程始于中国，在那里我们迅速成为一些欧洲最受欢迎品牌的运营合作伙伴，包括：',
                }}
            />
        ),
        secondStatement:
            '现在我们同步开启了中国品牌深度出海欧洲市场之旅，帮助中国品牌进驻欧洲市场，并实现品牌增长。',
    },
    aboutUsTextImage: {
        title:
            '我们定制的服务方案为欧洲品牌所有者提供了在亚洲市场实现品牌运营所需的一切，现在同步开始为中国品牌所有者在欧洲市场提供同样的服务。',
        description:
            '通过与值得信赖的经销商网络合作，我们将为您的品牌制定最佳的欧洲市场销售渠道组合方案，使其在分散和多样化的地区拥有无与伦比的影响力。',
    },
    ourTeam: {
        title: '我们的团队',
        team: [
            {
                img: '/assets/ellie.jpg',
                imgAlt: 'headshot of Ellie Adams - Founder & CEO',
                name: (
                    <div
                        dangerouslySetInnerHTML={{
                            __html: '<span class="english-font font-arima">Ellie Adams</span>',
                        }}
                    />
                ),
                title: (
                    <div
                        dangerouslySetInnerHTML={{
                            __html: '创始人 <span class="english-font font-arima">& CEO</span>',
                        }}
                    />
                ),
                paragraph:
                    '<p><span class="english-font font-poppins">Ellie</span> 是 <span class="english-font font-poppins">QIVA</span> 的首席架构师，现在的<span class="english-font font-poppins">QIVA</span>已经从小型初创企业成长为在英国、中国和日本设有分公司的全球性服务交付企业。</p><p><span class="english-font font-poppins">Ellie</span>拥有多年商业战略和运营方面的工作经验，曾在汤森路透和德勤工作。在德勤工作期间，<span class="english-font font-poppins">Ellie</span>作为商业战略顾问，负责市场运营规划和执行工作。</p><p>在 <span class="english-font font-poppins">QIVA</span>，<span class="english-font font-poppins">Ellie</span> 负责建立和实现我们在欧洲的客户运营目标以及架构欧洲市场运营体系。这涉及管理和维护我们的零售合作伙伴关系以及实施数字和媒体营销战略, 以实现客户期待的增长目标。</p><p><span class="english-font font-poppins">Ellie</span>毕业于清华大学和杜伦大学, 与她的家人和6只鸡在英国生活。</p>',
                linkedin: 'https://www.linkedin.com/in/ellie-adams-uk/',
                linkedinButton: (
                    <div
                        dangerouslySetInnerHTML={{
                            __html:
                                '在领英上关注<span class="english-font font-arima">Ellie</span>',
                        }}
                    />
                ),
            },
            {
                img: '/assets/Lei.jpg',
                imgAlt: 'headshot of Lei Jiang - Co-founder and COO',
                name: '姜磊',
                title: (
                    <div
                        dangerouslySetInnerHTML={{
                            __html: '联合创始人 <span class="english-font font-arima">& COO</span>',
                        }}
                    />
                ),
                paragraph:
                    '<p>同事们都亲切地称他为“磊哥”，他领导着 <span class="english-font font-poppins">QIVA</span> 在欧洲和中国建立消费品牌的运营基础设施建设。他对中国和欧洲市场的了解使他对中国品牌在欧洲市场必须驾驭的商业格局有着独特的理解。</p><p><span class="english-font font-poppins">Lei</span>出生在中国，后来移居德国学习工程学。在汽车行业的职业生涯之后，<span class="english-font font-poppins">Lei</span>意识到了他对快消品和创业的真正热情。曾就职于德国知名快消品公司的Lei与<span class="english-font font-poppins">Ellie</span>联手打造了<span class="english-font font-poppins">QIVA Global</span>。凭借他在工程领域掌握的数据建模和分析能力，<span class="english-font font-poppins">Lei</span>帮助我们的品牌在亚洲和欧洲市场上战胜竞争对手。</p><p><span class="english-font font-poppins">Lei</span>毕业于上海同济大学和柏林工业大学，和家人生活在德国柏林。</p>',
                linkedin: 'https://www.linkedin.com/in/lei-jiang-a95a24218',
                linkedinButton: '在领英上关注磊',
            },
            {
                img: '/assets/Jieling.jpg',
                imgAlt: 'headshot of Jieling Zheng - Operations Director',
                name: '郑洁翎',
                title: '品牌运营总监',
                paragraph:
                    '<p>洁翎是<span class="english-font font-poppins">QIVA</span>运营的王牌，她聪明、果敢、充满个人魅力。在<span class="english-font font-poppins">QIVA</span>这家充满实力的初创公司里，洁翎完全释放了自己对快消品行业的热情。</p><p>洁翎硕士毕业于英国曼彻斯特大学，现居住和工作在四川成都，是个很能吃辣的姑娘。</p>',
                linkedin: 'https://www.linkedin.com/in/jieling-zheng-801234194',
                linkedinButton: '在领英上关注郑洁翎',
            },

            {
                img: '/assets/Haiyan.jpg',
                imgAlt: 'headshot of Haiyan - Digital Manager',
                name: '周海燕',
                title: '数字营销经理',
                paragraph:
                    '<p>海燕作为我们核心团队最年轻的成员，她为<span class="english-font font-poppins">QIVA</span>的品牌客户带来了迅速发展壮大的Z时代消费群体。</p><p>在加入<span class="english-font font-poppins">QIVA</span>之前，海燕曾在一家实力雄厚的MCN公司工作。在那里，海燕积攒了很多社交媒体运营的经验。</p>',
                linkedin: 'https://www.linkedin.com/in/haiyan-zhou-6674b217a',
                linkedinButton: '在领英上关注海燕',
            },
            {
                img: '/assets/Grace.jpg',
                imgAlt: 'headshot of Grace Liu - Company Secretary',
                name: '刘新静',
                title: '公司秘书',
                paragraph:
                    '<p><span class="english-font font-poppins">Grace</span>熟练掌握中文、英语和德语，分管<span class="english-font font-poppins">QIVA</span>英国和<span class="english-font font-poppins">QIVA</span>中国的财务、行政和人事工作，她将我们整个团队牢牢团结在一起，并给大家创造了一个愉快和高效的工作氛围。</p>',
                linkedin: 'https://www.linkedin.com/in/xinjing-liu-568044173',
                linkedinButton: (
                    <div
                        dangerouslySetInnerHTML={{
                            __html:
                                '在领英上关注<span class="english-font font-arima">Grace</span>',
                        }}
                    />
                ),
            },
            {
                img: '/assets/Akuma_rob.jpg',
                imgAlt: 'headshot of Akuma & Rob - Designer Team',
                name: (
                    <div
                        dangerouslySetInnerHTML={{
                            __html: '<span class="english-font font-arima">Akuma & Rob</span>',
                        }}
                    />
                ),
                title: '设计师',
                paragraph:
                    '<p><span class="english-font font-poppins">Akuma</span>和<span class="english-font font-poppins">Rob</span>作为<span class="english-font font-poppins">QIVA</span>设计团队的灵魂，他们将东西方艺术结合在一起，使我们的创意设计拥有了跨文化风格。</p>',
            },
        ],
    },
    ourValues: {
        title: '我们的价值',
        brand: {
            title: '品牌',
            features: [
                {
                    title: '目标',
                    description: '我们致力于打造能够为消费者创造价值的品牌。',
                },
                {
                    title: '消费者至上',
                    description: '我们相信愿意倾听消费者的真实声音才能赢得他们对品牌的忠诚度。',
                },
                {
                    title: '追求品质',
                    description: '我们执着地为客户提供品质服务。',
                },
            ],
        },
        operation: {
            title: '运营',
            features: [
                {
                    title: '创新',
                    description: '我们将尝新视为学习和提升的催化剂。',
                },
                {
                    title: '追求卓越',
                    description: '我们在执行过程中一直追求高品质服务。',
                },
                {
                    title: '韧性',
                    description: '我们以坚韧的态度和适应能力应对所有挑战。',
                },
            ],
        },
        partner: {
            title: '伙伴',
            features: [
                {
                    title: '信任',
                    description:
                        '我们努力与我们的合作伙伴和客户建立良好的关系，他们坚信可以信任和依赖我们。',
                },
                {
                    title: '全球思维',
                    description: '我们倡导多元化思维和多样化背景。',
                },
                {
                    title: '多语言环境',
                    description:
                        '我们致力于跨语言和跨文化的平等协作，这同样适用于我们的客户和他们的消费群体。',
                },
            ],
        },
    },
    ourWorkBanner: {
        title: '我们的案例',
        description:
            '我们与大型跨国公司合作来推广其追求高增长的挑战者品牌，目的是实现它在全球市场上的版图规划以及可持续的增长。',
    },
    ourWorkQuote: {
        quote: '“我们需要一个可以在零售领域执行的合作伙伴”',
        company: (
            <div
                dangerouslySetInnerHTML={{
                    __html:
                        '<span class="english-font font-arima">Ecotone</span> <cite>(倍优禾)</cite>',
                }}
            />
        ),
    },
    allOurWork: {
        title: '我们正在执行的案例',
    },
    ideasBanner: {
        title: '我们的理念',
        description:
            '创新需要可执行并具有差异化---那种可以激发消费者的兴趣, 同时将新类目和新兴市场带入现实的想法。',
    },
    caseStudies: {
        title: '我们的案例',
        business: '业绩成果',
        portfolio: 'Portfolio:',
        export: 'Export:',
        about: '关于',
        minssion: 'Mission:',
        change: '变革的必要性：',
        solution: '我们的解决方案：',
        feedback: '客户反馈',
        backButton: '返回主页',
        nextButton: '下个案例',
        ironWomen: {
            projectName: '女性补铁',
            projectDesc: 'Floradix (铁元) 如何通过营销与销售紧密联动而成为补铁保健品类目的销售冠军',
            businessOne:
                '> 2020天猫国际双十一销售额超过2000万元人民币 (对比2019年双十一销售额仅为490万元人民币)',
            businessTwo: '> 双十一前期蓄水，超过1500万社交媒体平台触达量',
            businessThree: '> 7分钟内售出超过3.4万瓶铁元',
            businessClass: 'lg:px-20',
            headerImgClass: 'pb-16 relative',
            headerImg: '/assets/iron-women-main-case.png',
            headerAlt: 'display of Floradix product',
            logoAward: 'block',
            modelImg: '/assets/iron-women-model.png',
            modelAlt: 'a woman model displaying the Floradix product',
            aboutTitle: 'Salus',
            aboutFirstTitle: 'Salus是谁：',
            aboutFirstDesc: '德国百年草本保健品制造商，以Floradix铁元品牌闻名',
            aboutSecondTitle: '为什么选择Salus：',
            aboutSecondDesc: '在保护自然和环境的同时鼓励健康生活',
            aboutThirdTitle: 'Salus如何实现愿景：',
            aboutThirdDesc: '在植物栽培中使用最高的质量管理、社会责任和环境保护标准',
            fourthTitle: false,
            changeDesc:
                'Salus Haus---作为Floradix® 铁元品牌的拥有者，希望通过推动对铁剂的市场需求，扩大在中国的业务',
            changeImg: '/assets/iron-women-main-case.png',
            changeAlt: 'display of Floradix product',
            imperativeClass: 'pb-2',
            solutionClass: 'sm:grid-cols-2',
            solutionGridSpanOne: 'lg:col-span-2',
            solutionGridSpanClass: 'lg:grid-cols-3',
            solutionTitleOne: '准确地运用数据：',
            solutionDescOne:
                '我们使用“小数据”来分析品牌在电商站内和站外的生态环境，来预判业绩前景和快速增长机遇。',
            solutionTitleSecond: '提高成交转化率：',
            solutionDescTwo:
                '我们使用“小数据”来识别消费者对于健康和美容方面的需求。 我们利用该细分类目来扩大我们的目标受众范围，并实现了成交转化率的翻倍。',
            solutionTitleThird: '电商优化：',
            solutionDescThree:
                '我们优化了品牌旗舰店运营，从站外引导搜索流量到站内营销投放，使双十一的销售额增加了370%。',
            solutionTitleFour: '数字放大：',
            solutionDescFour:
                '我们与超过500位达人合作，发布符合Salus目标消费者需求的高品质的消费者原创内容。',
            solutionImg: '/assets/floradix-solution.png',
            solutionAlt: 'display of Floradix product',
            firstQuotePara:
                '“Salus委托QIVA制定一份营销策划，以刺激消费者对Floradix铁元品牌的需求增长。与QIVA合作，我们提高了我们的运营能力，结果也是非常出色。很高兴与Ellie和QIVA团队合作，我们绝对推荐他们的服务。”',
            jobTitle: '亚太和大中华地区主管',
            company: 'Salus (Floradix铁元) ',
            clientClass:
                'flex w-full bg-darkblue max-h-734 py-20 my-16 flex-col items-center justify-center',
            clientImg: '/assets/floradix-banner.png',
            clientAlt: 'banner advert of Floradix',
            clientPostCard: '/assets/floradix-postcard.png',
            clientPostCardAlt: 'flordix postcard of product',
            nextProject: '/snack-revolution',
        },
        grenade: {
            projectName: '零食新主张',
            projectDesc:
                '零食新主张---Grenade (燃拓) 如何通过扩大营销和销售触达范围来解决在中国市场品牌认知度低的问题',
            headerClass: 'lg:w-full',
            businessOne: '天猫旗舰店销量增长了1269%',
            businessTwo: '2.400万触达量',
            businessThree: '搜索量增长了1500%',
            businessClass: 'lg:px-20',
            headerImgClass: 'pb-16',
            video: '/assets/grenade-video-2.mp4',
            headerAlt: 'display of grenade bar product',
            modelImg: '/assets/grenade-model.png',
            modelAlt: 'a woman model displaying the Grenade bar product',
            aboutTitle: 'Grenade (燃拓)',
            aboutFirstTitle: 'Grenade (燃拓) 是谁：',
            aboutFirstDesc: '英国增长最快的功能性营养品品牌',
            aboutSecondTitle: '为什么选择Grenade (燃拓) ：',
            aboutSecondDesc: '激发健身潜能',
            aboutThirdTitle: 'Grenade (燃拓) 如何实现愿景：',
            aboutThirdDesc: '永不甘于平庸',
            grenadeAboutOne: 'pl-6',
            grenadeAboutTwo: 'pl-6',
            grenadeAboutThree: 'pl-6',
            grenadeAboutFour: 'pl-6',
            changeDesc:
                '在中国经历销售停滞和品牌知名度低的困境后，燃拓希望找到一个能够为其市场需求提供全面解决方案的合作伙伴。',
            grenadeClassImgs: 'flex flex-col sm:flex-row space-y-6 sm:space-y-0 sm:space-x-6',
            orangeImg: '/assets/grenade-blue-advert.png',
            orangeAlt: 'Grenade advert of the bars',
            orangeDivBox: 'flex sm:flex-1 items-center justify-center p-10 bg-grenadeorange ',
            blueDivBox: 'flex sm:flex-1 items-center justify-center p-10 bg-grenadeblue',
            blueImg: '/assets/grenade-workout.png',
            blueAlt: "advert of a woman working out showcasing Grenade's product",
            imperativeClass: 'pb-10',
            solutionClass: 'sm:grid-cols-2',
            solutionGridSpanOne: 'lg:col-span-2',
            solutionGridSpanClass: 'lg:grid-cols-3',
            solutionTitleOne: '产品组合策略：',
            solutionDescOne:
                '我们将产品销售重点从蛋白酱转移到蛋白棒，大大提高了客单价，并增加了盈利能力',
            solutionTitleSecond: '利用用户原创内容拉动流量：',
            solutionDescTwo: '我们激发了超过400万触达量，并成功引流到Grenade (燃拓) 品牌旗舰店',
            solutionTitleThird: '内容营销：',
            solutionDescThree:
                '我们在五个主要社交媒体渠道建立了一个忠诚的拥护者社区，利用他们的讲故事能力来建立品牌信任',
            solutionTitleFour: '优化搜索：',
            solutionDescFour: '我们捕获寻找健康零食和蛋白棒的搜索流量，拉升了店铺访客量',
            solutionImg: '/assets/grenade-solution.png',
            solutionAlt: 'A woman displaying the grenade product',
            firstQuotePara:
                '“作为品牌持有者，我们寻求能满足本地市场需求的全球化解决方案。我们希望能够与一个可以提供一致性方案的合作伙伴合作，而这正是QIVA Global可以做到的。QIVA接受并执行了我们的指示，通过与新的分销伙伴建立合作，将Grenade (燃拓) 从一个小型的跨境业务模式发展到全面的本地市场渠道布局。”',
            jobTitle: '国际销售主管',
            company: '燃拓',
            clientPaddingClass: 'mb-16',
            nextProject: '/nandos',
        },
        nandos: {
            projectName: '把Nando\'s带回家',
            projectDesc: 'Nando\'s (南逗) 如何利用品牌情怀加速推动其在中国的零售业务',
            headerClass: 'lg:w-full',
            businessOne: '搜索量增加了285%',
            businessTwo: '支付人数增加了200%',
            businessThree: 'GMV增加了208%',
            businessClass: 'lg:px-20',
            modelImg: '/assets/nandos-1.jpg',
            modelAlt: "Bowl of food and Nando's sauce",
            aboutTitle: 'Nando\'s (南逗) ',
            aboutFirstTitle: 'Nando\'s (南逗) 是谁：',
            aboutFirstDesc: '跨国快餐连锁品牌',
            aboutSecondTitle: '为什么选择Nando\'s (南逗) ：',
            aboutSecondDesc: '将人和社区聚集在一起',
            aboutThirdTitle: 'Nando\'s (南逗) 如何实现愿景：',
            aboutThirdDesc: '创造难忘的体验',
            grenadeAboutOne: 'pl-6',
            grenadeAboutTwo: 'pl-6',
            grenadeAboutThree: 'pl-6',
            grenadeAboutFour: 'pl-6',
            changeDesc:
                'Nando\'s (南逗) 希望利用其在亚洲消费者中的海外知名度，在亚洲市场创造新的增长点。',
            grenadeClassImgs: 'flex flex-col sm:flex-row space-y-6 sm:space-y-0 sm:space-x-6',
            orangeImg: '/assets/cny-nandos.jpg',
            orangeAlt: "Nando's sauces",
            orangeDivBox: 'flex sm:flex-1 items-center justify-center p-10 bg-nandosorange',
            blueDivBox: 'flex sm:flex-1 items-center justify-center p-10 bg-nandospink',
            blueImg: '/assets/nandos-2.jpg',
            blueAlt: "Bowl of food and nando's sauce",
            imperativeClass: 'pb-10',
            solutionClass: 'sm:grid-cols-2',
            solutionGridSpanOne: 'lg:col-span-2',
            solutionGridSpanClass: 'lg:grid-cols-3',
            solutionTitleOne: '定位目标客群：',
            solutionDescOne:
                '我们针对具有 Nando\'s (南逗) 记忆的前国际学生，创建一个共享 Nando\'s (南逗) 经验的社区，并通过复刻Nando\'s (南逗) 食谱来产生零售商品与购买决策之间联系',
            solutionTitleSecond: '数据主导决策：',
            solutionDescTwo: '我们引入销售和营销结合的数据分析方式产生市场洞察力并引导决策',
            solutionTitleThird: '品牌放大：',
            solutionDescThree:
                '我们与主流社交媒体平台的达人合作，扩大并推动媒体流量向线上和线下渠道移动',
            solutionTitleFour: '渠道管理：',
            solutionDescFour:
                '我们优化了电商店铺搜索关键词和视觉设计，提高了搜索量和点击率，并引入了新的跟踪方法来跟踪消费者行为',
            solutionImg: '/assets/Beijing-Olympics.jpg',
            solutionAlt: "Nando's sauce playing hockey",
            firstQuotePara:
                '“自从与QIVA合作以来，我们已经看到了销售和营销上巨大的成功。作为一个品牌，我们致力于制订全球化策略和在细分本地市场营销。有了QIVA作为我们的合作伙伴，我们对我们的本地执行非常有信心。”',
            jobTitle: '国际市场主管',
            company: '南逗',
            clientPaddingClass: 'mb-16',
            nextProject: '/iron-women',
        },
    },
}
