import React, { useState, useEffect } from 'react'
import { Link } from "gatsby"

const CookiePopUp = () => {
  const [showCookiePopup, setShowCookiePop] = useState(false)

  const acceptCookies = () => {
    localStorage.setItem('cookie-notice', 'accepted');
    setShowCookiePop(false)
  }

  useEffect(() => {
    setTimeout(() => {
      document.getElementById('box').classList.remove('scale-0');
      document.getElementById('box').classList.add('scale-100');
    }, 1000);
    
    if (localStorage.getItem('cookie-notice') !== 'accepted') {
      setShowCookiePop(true)
    }
  }, [])

  return (
    <div
    id="box"
    className={`${showCookiePopup ? 'lg:flex' : 'hidden'} transform transition-all duration-300 ease-out z-50 fixed bottom-0 bg-white text-black scale-0 inset-x-0 rounded-tl-lg rounded-tr-lg py-3 px-8 space-y-4 lg:space-y-0 lg:justify-between items-center mx-10 border border-gray-300`}
  >
    <p className="text-sm font-poppins text-grey-800">
      This website uses cookies to ensure you get the best experience on our website. &nbsp;
      <Link to="/cookie-policy" className="underline">Learn more</Link>
    </p>
    <button
      onClick={() => acceptCookies()}
      className="text-sm underline text-darkblue p-2"
    >
      Close
    </button>
  </div>
  )
}

export default CookiePopUp
