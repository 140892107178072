import { m } from "framer-motion";
import React from "react";

export default {
  appHeader: {
    buttonTitle: "Menu",
    menu: [
      {
        title: "Our services",
        link: "/our-services",
      },
      {
        title: "Our reach",
        link: "/our-reach",
      },
      {
        title: "About us",
        link: "/about-us",
      },
      {
        title: "Our work",
        link: "/our-work",
      },
      {
        title: "Ideas",
        link: "/ideas",
      },
    ],
    contactUs: "Contact us",
  },
  homeBanner: {
    title: (
      <div
        dangerouslySetInnerHTML={{
          __html:
            "Your route to market in China and APAC",
        }}
      />
    ),
    buttonTitle: "Watch now",
    slogan: "We build global brands",
  },
  aboutUs: {
    title: "About us",
    subTitle: (
      <h5
        className="text-md sm:text-sm md:text-md lg:text-xl xl:text-2xl"
        dangerouslySetInnerHTML={{
          __html:
            'Brand owners are constantly challenged with the question: <span className="font-bold"> what is the right pathway to growth in Asia? </span>',
        }}
      />
    ),
    description:
      "Whether it’s entering a new market, managing a trade channel or launching a new product range, we provide our brand partners with a singular solution to accelerate growth and create value.",
    buttonTitle: "Find out more",
  },
  ourWork: {
    title: "Our work",
    description:
      "We combine operational excellence with a meticulous focus on brand growth to create industry leaders.",
    projectOne: {
      title: "Iron women",
      description:
        "How Floradix became the market leader in iron supplements through integrated sales & marketing",
    },
    projectTwo: {
      title: "Snack revolution",
      description:
        "How Grenade expanded sales and marketing reach to overcome low consumer awareness in China",
    },
    projectThree: {
      title: "Nando’s at home",
      description:
        "How Nando’s has used nostalgia to turbo charge its grocery business in China",
    },
    buttonTitle: "Read more",
  },
  whyUs: {
    title: "Why us?",
    subTitle:
      "We are QIVA Global; an award-winning operating partner to international brands in Asia.",
    brand: {
      title: "Brand",
      features: [
        {
          title: "Results",
          description:
            "We let the records of our clients speak for themselves.",
        },
        {
          title: "Marketing capability",
          description:
            "We are experts in the techniques and processes that underpin successful brand building in this diverse region.",
        },
        {
          title: "Marketing approach",
          description:
            "We create tailored and measurable solutions for clients, guided by a long-term vision.",
        },
      ],
    },
    operation: {
      title: "Operations",
      features: [
        {
          title: "End-to-end and omni-channel",
          description:
            "We execute a seamless service across eCommerce, physical stores and new retail channels.",
        },
        {
          title: "eCommerce excellence",
          description: "we are a 5* accredited eCommerce partner.",
        },
        {
          title: "Data-driven",
          description: "We measure what matters, not vanity metrics.",
        },
      ],
    },
    partner: {
      title: "Partners",
      features: [
        {
          title: "Long-term",
          description:
            "We build brands to create IMPACT on the most important issues of our time.",
        },
        {
          title: "Global outlook",
          description:
            "We apply diverse thinking and multilingualism to drive performance for our clients.",
        },
        {
          title: "Team",
          description:
            "We combine strengths with our clients to overcome complex problem sets.",
        },
      ],
    },
  },
  ourModel: {
    title: "Our model",
    subTitle:
      "Our end-to-end solution is connected and interoperated through little data;",
    description:
      "We use it to provide a singular solution for your brand in Asia.",
    buttonTitle: "Our Services",
  },
  ourClients: {
    title: "Our portfolio",
  },
  ideas: {
    title: "Ideas",
    description:
      "Innovation requires actionable and differentiated ideas - the kind that excite customers and bring new categories and markets into being.",
  },
  quotes: {
    title: "What our brand partners say…",
    testimonials: [
      {
        quote:
          "“As brand owners, we look for global solutions that meet local market needs. We want to work with one partner that delivers consistency and consistently…and that’s exactly what we get with QIVA Global”",
        role: "Head of International Sales",
        company: "Grenade",
      },
      {
        quote:
          "“We have seen measurable impact across sales and marketing metrics since starting to work with QIVA. As a brand, we are all about working globally and acting locally. With QIVA as our partner, we feel really confident in our local execution”",
        role: "Head of International Markets",
        company: "Nando's",
      },
      {
        quote:
          "“Working with the QIVA team has been invaluable to our business. Together we have taken Bjorg from new entrant to full market, omni-channel coverage within a one-year period. Our brand is experiencing massive growth in China and we’re really excited about what’s to come next”",
        role: "Head of International Sales",
        company: "Ecotone (Bjorg)",
      },
      {
        quote:
          "“Working with QIVA’s marketing team, we have developed a great relationship with our consumers in China. Over 4 months, the number of store visitors coming to us through search has increased 280%”",
        role: "Head of Asia Marketing",
        company: "Nando's",
      },
      {
        quote:
          "“QIVA’s unique marketing approach has increased our brand search 1500% on Tmall, driving huge growth in our protein bar range”",
        role: "Head of China",
        company: "Grenade",
      },
      {
        quote:
          "“The QIVA team have provided us with the clarity and confidence we need to take our brand into new Asian markets.”",
        role: "Head of Sales",
        company: "Edgard & Cooper",
      },
      {
        quote:
          "“QIVA are our trusted partners leading our growth in Asia, and have been nothing but a pleasure to deal with from the first instance. Professional and extremely brand focused”",
        role: "CEO",
        company: "BeyondNRG",
      },
      {
        quote:
          "“As a luxury brand we needed a partner who understood our value proposition and could operationalise it in China. Working with QIVA has been a great experience”",
        role: "Head of Asia Consumer",
        company: "vVardis",
      },
      {
        quote:
          '"Salus commissioned QIVA to implement a plan to increase consumer demand for the Floradix brand. Working with QIVA, we have strengthened our capability and the results have been fantastic. It’s a great pleasure working with Ellie and team. We can absolutely recommend their services"',
        role: "Head of Asia & Greater China",
        company: "Salus (Floradix)",
      },
    ],
  },
  featuredIn: {
    title: "Featured in",
  },
  contactUs: {
    title: "Contact us",
    uk: "United Kingdom",
    asia: "Asia",
    china: "China",
    japan: "Japan",
  },
  callToAction: {
    title: "Your operating partner in Asia",
    description: "Follow us on LinkedIn",
    buttonTitle: "Follow us",
  },
  footer: {
    privacy: "Privacy notice",
    contact: "Contact us",
  },
  ourServicesBanner: {
    title: "Our integrated solution",
    description: (
      <div
        className="text-md sm:text-sm lg:text-md font-poppins space-y-4"
        dangerouslySetInnerHTML={{
          __html:
            "<p> We support your brand from concept right through to market execution via an integrated set of services that cover the lifecycle of brand development. </p><p> Our service is unique, giving you comprehensive and effective brand management by a team of highly skilled market specialists.</p><p> Tailored and deliberate, you can select the exact expertise you need to deliver your strategy and realise your ambitions for your brand’s profitability. </p>",
        }}
      />
    ),
  },
  features: {
    strategy: {
      type: "Strategy",
      title: "Seed",
      subHeading: "Equip for entry & growth",
      firstPara:
        "From market entry to operational strategy, our data methodology helps brands to understand people and navigate Asian markets with confidence.",
      secondPara:
        "Our model enables clients to make clear, timely and purposeful choices. Ones which are informed by quantitative methodologies and on-the-ground relationships with retailers, distributors and technology platforms.",
      features: [
        {
          id: '1',
          title: "Opportunity evaluation",
        },
        {
          id: '2',
          title: "Market entry strategy",
        },
        {
          id: '3',
          title: "Cross-border",
        },
        {
          id: '4',
          title: "eCommerce strategy",
        },
        {
          id: '5',
          title: "Operational strategy",
        }
      ]
    },
    brand: {
      type: "Brand building",
      title: "Sow",
      subHeading: "Create resonance and trust",
      firstPara:
        "Generating influence is key to inspiring customers to trust your brand. Whether that’s ‘always on’ marketing, reaching key opinion leaders, producing creative design or  carrying out effective PR.",
      secondPara:
        "We optimise brand building through real-time connection with your customers and sales platforms. This approach enables forensic understanding of target audiences, personalisation of messaging and innovative delivery.",
      features: [
        {
          id: '1',
          title: "Social media activation",
        },
        {
          id: '2',
          title: "Communications strategy",
        },
        {
          id: '3',
          title: "Design",
        },
        {
          id: '4',
          title: "Official marketing",
        },
        {
          id: '5',
          title: "Non-official marketing (KOLs)",
        }
      ]
    },
    sales: {
      type: "Sales",
      title: "Harvest",
      subHeading: "Convert trust to commitment",
      firstPara:
        "We help brands to sell with resilience in a landscape that is dynamic, infused with technology and integrated with social. We do this in sectors as varied as cross-border eCommerce and offline retail.",
      secondPara:
        "Our model involves creating sales channels optimised to your market opportunity, integrated with marketing, and powered by seamless logistics.",
      features: [
        {
          id: '1',
          title: "eCommerce store management / TP/ D2C",
        },
        {
          id: '2',
          title: "Retail integration (020)",
        },
        {
          id: '3',
          title: "Live-stream",
        },
        {
          id: '4',
          title: "Social commerce",
        }
      ]
    },
  },
  howItWorks: {
    title: "How it all works together",
    description:
      "We use little data to help you understand your target customer. We help you deliver the right message, at the right moment, to convert their interest into sales. This process never stops – your customer is not static; nor should you be.",
  },
  ourServicesInAction: {
    title: "See our work in action",
  },
  ourReachBanner: {
    title: "Our reach",
    description: "What you get working with us",
  },
  ourReachTestimonial: {
    quote: (
      <div
        dangerouslySetInnerHTML={{
          __html:
            "<p><strong>“</strong> As brand owners, we look for<strong> global solutions</strong> that meet<strong> local market needs</strong>. We want to work with<strong> one partner</strong> that delivers<strong> consistency and consistently…</strong> and that’s <strong>exactly what we get with QIVA Global &nbsp;”</strong></p>",
        }}
      />
    ),
    role: "Head of International Sales",
    company: "Grenade",
  },
  ourReachStatement: {
    description:
      "We work with large multinationals through to high growth challenger brands to deliver ambitious and sustainable growth for their brands in Asian markets.",
  },
  ourReachBrands: {
    title: "We give our brands access to:",
    keyFacts: [
      {
        number: "9",
        title: "markets",
        description:
          "(Asia: China, Japan, S.Korea, Malaysia, Singpore, Europe: UK & DACH)",
      },
      {
        number: "50+",
        title: "distributors",
      },
      {
        number: "120+",
        title: "major chain retailers",
      },
      {
        number: "30+",
        title: "Key eCommerce marketplaces",
      },
      {
        number: <div dangerouslySetInnerHTML={{ __html: '<div class="flex justify-center space-x-2"><div>30</div> <div class="flex justify-center"><div class="-mt-0.5">-</div><div>></div></div> <div>40</div></div>' }} />,
        title: "Key Social Media channels",
      },
    ],
  },
  ourReachStats: {
    title:
      "Extensive reach and unmatched penetration across health & personal care and food & drink in Asia.",
    description:
      "Our multi-channel approach means you get to engage with a wide breadth of consumers, allowing you to maximise sales and generate new opportunities for your brands.",
    quote:
      "“We went from new entrant to having a presence in premium supermarket retailers like Olé”",
    company: "Ecotone (Bjorg)",
  },
  whatWeDo: {
    title: "What we do",
    buttonTitle: "Find out more",
    services: "Our services",
    ideas: "Ideas",
    work: "Our work",
    aboutUs: "About us",
  },
  aboutUsBanner: {
    title: "About us",
    description: (
      <div
        className="text-md sm:text-sm lg:text-md xl:text-lg font-poppins space-y-4 lg:space-y-6"
        dangerouslySetInnerHTML={{
          __html:
            "<p> We are a consumer brand services business working with some of the most dynamic consumer brands of our time. </p> <p> We’re here to help you grow from unknown outsider to market leader, creating new standards of impact and performance without compromising on quality.</p>",
        }}
      />
    ),
  },
  aboutUsStatement: {
    statement:
      "Established in 2016, our journey started in China where we quickly became the operating partner to some of Europe’s best loved brands, including;",
  },
  aboutUsTextImage: {
    title:
      "Our singular solution provides brand owners with everything they need from one outsourced partner, in China and now in Japan, South Korea, Malaysia and Singapore.",
    description:
      " Working with a network of trusted distributors, we put in place the optimal channel arrangements for your brand giving you unrivalled reach in this fragmented and diverse region.",
  },
  ourTeam: {
    title: "Our team",
    team: [
      {
        img: "/assets/ellie.jpg",
        imgAlt: "headshot of Ellie Adams - Founder & CEO",
        name: "Ellie Adams",
        title: "Founder & CEO",
        paragraph:
          "<p>Ellie is CEO & Founder of QIVA Global, grown from the ground up to amplify progressive, impact-focused consumer brands internationally.</p><p>She is passionate about CPG for its ability to generate large-scale positive impact, disrupt status quo and empower consumers to contribute positively to society, communities and the planet. A fluent Mandarin speaker, Ellie has led the commercial strategy for some of Europe’s best-loved brands in the Asia region.</p><p>Ellie started her career working for Thomson Reuters as a Business Graduate. She worked on sales and strategy projects in China and the UK before moving to Washington D.C. for a role in Government Affairs. Deciding politics really wasn’t her thing, Ellie joined Monitor Deloitte, a strategy consultancy, where she worked with commercial clients on business operations and strategy.</p><p>Ellie is a graduate of Tsinghua (Beijing) and Durham Universities.</p>",
        linkedin: "https://www.linkedin.com/in/ellie-adams-uk/",
        linkedinButton: "Follow on Linkedin",
      },
      {
        img: "/assets/Lei.jpg",
        imgAlt: "headshot of Lei Jiang - Co-founder and COO",
        name: "Lei Jiang",
        title: "Co-founder and COO",
        paragraph:
          "<p>Affectionately called “Lei-Ge” (Big Brother) by his work colleagues, Lei has led the charge in building the operational infrastructure QIVA uses to build consumer brands in Europe and China. His knowledge of Chinese and European markets have equipped him with unique understanding of the commercial landscape Chinese brands must navigate.</p><p>Born in China, Lei moved to Germany to study Engineering. Following a short-lived career in automotives, Lei realised his true passion for CPG and entrepreneurship. Having worked for a well-known German FMCG company, Lei joined forces with Ellie to build QIVA Global. Armed with his jiu-jitsu analytical skills from a near-miss career in engineering, Lei helps our brands outmaneuver their competitors in Asian and European markets. </p><p>Lei is a graduate of Shanghai Tongji University and Technical University Berlin. Lei lives in Berlin with his family.</p>",
      },
      {
        img: "/assets/Jieling.jpg",
        imgAlt: "headshot of Jieling Zheng - Operations Director",
        name: "Jieling Zheng",
        title: "Brand Operations Director",
        paragraph:
          "<p>Combining disarming charm with bone-dogged determination, Jieling is the not-so-secret weapon behind QIVA’s operational successes. Following a short career in corporate land, Jieling discovered she was much more at home in a punchy start-up where she could unleash her appetite for all things CPG.</p><p>Jieling has a master’s degree from Manchester University and now lives in Sichuan, China, where her tolerance for spice is unrivalled.</p>",
        linkedin: "https://www.linkedin.com/in/jieling-zheng-801234194",
        linkedinButton: "Follow on Linkedin",
      },

      {
        img: "/assets/Haiyan.jpg",
        imgAlt: "headshot of Haiyan - Social Media Manager",
        name: "Haiyan",
        title: "Social Media Manager",
        paragraph:
          "<p>The youngest (and smiliest) member of our crew, Haiyan, brings QIVA’s portfolio the (Generation) “Z” it needs to engage Asia’s fastest growing generation of consumers.</p><p>Prior to joining QIVA, Haiyan worked for a social media and influencer agency with offices throughout Asia. Here she learned the art of crafting persuasive messaging to drive consumer behaviour. Don’t mess with her on social! </p>",
      },
      {
        img: "/assets/Grace.jpg",
        imgAlt: "headshot of Grace Liu - Company Secretary",
        name: "Grace Liu",
        title: "Company Secretary",
        paragraph:
          "<p>A fluent English, German and native Chinese speaker, Grace deploys all manner of languages to keep our team in check. Grace runs our international offices and creates the conditions for happy and high performing teams.</p><p>A mum of two young children, there is very little that Grace cannot do.</p>",
      },
      {
        img: "/assets/Akuma_rob.jpg",
        imgAlt: "headshot of Akuma & Rob - Designer Team",
        name: "Akuma & Rob",
        title: "Design",
        paragraph:
          "<p>The Yin and Yang of our design team, Rob and Akuma bring ideas to life through artwork. Combining East and West artistic styles, they make our brands stand out with cross-cultural flair.</p>",
      },
    ],
  },
  ourValues: {
    title: "Our values",
    brand: {
      title: "Brand",
      features: [
        {
          title: "Purpose",
          description:
            "We are committed to building brands that can inspire and create value for customers.",
        },
        {
          title: "Customer first",
          description:
            "We believe loyalty should be earned, which means hearing what customers have to say.",
        },
        {
          title: "Pursue quality",
          description:
            "We are ruthless in the pursuit of quality for our clients.",
        },
      ],
    },
    operation: {
      title: "Operations",
      features: [
        {
          title: "Innovation",
          description:
            "We celebrate experimentation as a catalyst for learning and improvement.",
        },
        {
          title: "Drive excellence",
          description:
            "We believe quality is an outcome of process and quality execution.",
        },
        {
          title: "Resilience",
          description: "We approach challenges with tenacity and adaptability.",
        },
      ],
    },
    partner: {
      title: "Partners",
      features: [
        {
          title: "Trust",
          description:
            "We strive to build fantastic relationships with our partners and clients where they know they can rely on us.",
        },
        {
          title: "Global mindset",
          description:
            "We are advocates for diverse thinking and a diversity of backgrounds.",
        },
        {
          title: "Multilingualism",
          description:
            "We are committed to working equally across languages and cultures, reflecting our clients and their customers",
        },
      ],
    },
  },
  ourWorkBanner: {
    title: "Our work",
    description:
      "We work with large multinationals through to high growth challenger brands to deliver ambitious and sustainable growth for their brands in Asian markets",
  },
  ourWorkQuote: {
    quote: '"We needed a partner who could execute in retail"',
    company: (
      <div
        dangerouslySetInnerHTML={{
          __html: "Ecotone <cite>(Bjorg)</cite>",
        }}
      />
    ),
  },
  allOurWork: {
    title: "Our work in action",
  },
  ideasBanner: {
    title: "Ideas",
    description:
      "Innovation requires actionable and differentiated ideas - the kind that excite customers and bring new categories and markets into being.",
  },
  caseStudies: {
    title: "Our work",
    business: "Business Impact",
    portfolio: "Portfolio:",
    export: "Export:",
    about: "About",
    minssion: "Mission:",
    change: "Imperative for change:",
    solution: "Our Solution:",
    feedback: "Client Feedback",
    backButton: "Back to home",
    nextButton: "Next Project",
    ironWomen: {
      projectName: "Iron women",
      projectDesc:
        "How Floradix became the market leader in iron supplements through integrated sales & marketing",
      businessOne: "> 20M RMB: Tmall Global sales on 11/11/20",
      businessTwo: "> 15M: SoMe engagements in the lead up to 11/11",
      businessThree: "> 34,000 bottles of Floradix sold within seven minutes",
      businessClass: "lg:px-20",
      headerImgClass: "pb-16 relative",
      headerImg: "/assets/iron-women-main-case.png",
      headerAlt: "display of Floradix product",
      logoAward: "block",
      modelImg: "/assets/iron-women-model.png",
      modelAlt: "a woman model displaying the Floradix product",
      aboutTitle: "Salus",
      aboutFirstTitle: "Who:",
      aboutFirstDesc:
        "Herbal health food manufacturer from Germany, famous for their Floradix brand",
      aboutSecondTitle: "Why:",
      aboutSecondDesc:
        "To inspire healthy living while protecting nature and the environment",
      aboutThirdTitle: "How:",
      aboutThirdDesc:
        "Using the highest standards of quality, social responsibility and environmental protection in plant cultivation",
      fourthTitle: false,
      changeDesc:
        "Salus Haus – owner of the Floradix® brand – wanted to scale its presence in China by driving demand for its iron tonic.",
      changeImg: "/assets/iron-women-main-case.png",
      changeAlt: "display of Floradix product",
      imperativeClass: "pb-2",
      solutionClass: "sm:grid-cols-2",
      solutionGridSpanOne: "lg:col-span-2",
      solutionGridSpanClass: "lg:grid-cols-3",
      solutionTitleOne: "Precision through data:",
      solutionDescOne:
        "We used little data to identify performance issues and opportunities for immediate growth; both inside and outside the eCommerce ecosystem.",
      solutionTitleSecond: "Improved conversion:",
      solutionDescTwo:
        "We used little data to identify a link between consumer demands for health and beauty. We used this theme to expand our target audience reach and double conversion rates.",
      solutionTitleThird: "eCommerce optimisation:",
      solutionDescThree:
        "We optimised the Flagship store, from search to onsite marketing, leading to a 370% increase in sales during 11.11.",
      solutionTitleFour: "Digital amplification:",
      solutionDescFour:
        "We worked with > 500 key opinion leaders to create high quality, organic content that spoke to the needs of Salus’ target consumer group.",
      solutionImg: "/assets/floradix-solution.png",
      solutionAlt: "display of Floradix product",
      firstQuotePara:
        '"Salus commissioned QIVA to implement a plan to increase consumer demand for the Floradix brand. Working with QIVA, we have strengthened our capability and the results have been fantastic. It’s a great pleasure working with Ellie and team. We can absolutely recommend their services"',
      jobTitle: "Head of Asia & Greater China",
      company: "Salus (Floradix)",
      clientClass:
        "flex w-full bg-darkblue max-h-734 py-20 my-16 flex-col items-center justify-center",
      clientImg: "/assets/floradix-banner.png",
      clientAlt: "banner advert of Floradix",
      clientPostCard: "/assets/floradix-postcard.png",
      clientPostCardAlt: "flordix postcard of product",
      nextProject: "/snack-revolution",
    },
    grenade: {
      projectName: "Snack revolution",
      projectDesc:
        "How Grenade expanded sales and marketing reach to overcome low consumer awareness in China",
      headerClass: "lg:w-full",
      businessOne: "1269% growth in Tmall sales",
      businessTwo: "4M engagements",
      businessThree: "1500% increase in search",
      businessClass: "lg:px-20",
      headerImgClass: "pb-16",
      video: "/assets/grenade-video-2.mp4",
      headerAlt: "display of grenade bar product",
      modelImg: "/assets/grenade-model.png",
      modelAlt: "a woman model displaying the Grenade bar product",
      aboutTitle: "Grenade",
      aboutFirstTitle: "Who:",
      aboutFirstDesc: "Fastest growing performance nutrition brand in UK",
      aboutSecondTitle: "Why:",
      aboutSecondDesc: "Inspire fitness goals",
      aboutThirdTitle: "How:",
      aboutThirdDesc: "Don’t do normal",
      grenadeAboutOne: "pl-6",
      grenadeAboutTwo: "pl-6",
      grenadeAboutThree: "pl-6",
      grenadeAboutFour: "pl-6",
      changeDesc:
        "Stagnant sales and low brand awareness – Grenade wanted to find a partner who could offer a comprehensive solution for their Asian market needs.",
      grenadeClassImgs:
        "flex flex-col sm:flex-row space-y-6 sm:space-y-0 sm:space-x-6",
      orangeImg: "/assets/grenade-blue-advert.png",
      orangeAlt: "Grenade advert of the bars",
      orangeDivBox:
        "flex sm:flex-1 items-center justify-center p-10 bg-grenadeorange ",
      blueDivBox:
        "flex sm:flex-1 items-center justify-center p-10 bg-grenadeblue",
      blueImg: "/assets/grenade-workout.png",
      blueAlt: "advert of a woman working out showcasing Grenade's product",
      imperativeClass: "pb-10",
      solutionClass: "sm:grid-cols-2",
      solutionGridSpanOne: "lg:col-span-2",
      solutionGridSpanClass: "lg:grid-cols-3",
      solutionTitleOne: "Portfolio strategy:",
      solutionDescOne:
        "We changed focus from spreads to protein bars, increasing average transaction value and improving profitability",
      solutionTitleSecond: "UGC to drive traffic:",
      solutionDescTwo:
        "We generated over 4M engagements to create organic buzz and drive traffic to Grenade’s flagship store",
      solutionTitleThird: "Content marketing:",
      solutionDescThree:
        "We built a community of loyal advocates across five major social media channels, leveraging their storytelling power to create trust",
      solutionTitleFour: "Search optimisation:",
      solutionDescFour:
        "We captured traffic looking for healthy snacks and protein bars, driving up store visits and UV",
      solutionImg: "/assets/grenade-solution.png",
      solutionAlt: "A woman displaying the grenade product",
      firstQuotePara:
        "“As brand owners, we look for global solutions that meet local market needs. We want to work with one partner that delivers consistency and consistently…and that’s exactly what we get with QIVA. They have taken our brief and run with it, taking us from a small cross-border presence to full-scale domestic coverage through new distribution partnerships”.",
      jobTitle: "Head of International Sales",
      company: "Grenade",
      clientPaddingClass: "mb-16",
      nextProject: "/nandos",
    },
    nandos: {
      projectName: "Nando’s at home",
      projectDesc:
        "How Nando’s has used nostalgia to turbo charge its grocery business in China",
      headerClass: "lg:w-full",
      businessOne: "285% increase in search",
      businessTwo: "200% increase in customers",
      businessThree: "208% increase in GMV",
      businessClass: "lg:px-20",
      modelImg: "/assets/nandos-1.jpg",
      modelAlt: "Bowl of food and Nando's sauce",
      aboutTitle: "Nando's",
      aboutFirstTitle: "Who:",
      aboutFirstDesc: "Multinational fast food chain",
      aboutSecondTitle: "Why:",
      aboutSecondDesc: "Bringing people and communities together",
      aboutThirdTitle: "How:",
      aboutThirdDesc: "Creating memorable experiences",
      grenadeAboutOne: "pl-6",
      grenadeAboutTwo: "pl-6",
      grenadeAboutThree: "pl-6",
      grenadeAboutFour: "pl-6",
      changeDesc:
        "Nando’s wanted to leverage their overseas popularity with Asian consumers to create new growth in Asian markets.",
      grenadeClassImgs:
        "flex flex-col sm:flex-row space-y-6 sm:space-y-0 sm:space-x-6",
      orangeImg: "/assets/cny-nandos.jpg",
      orangeAlt: "Nando's sauces",
      orangeDivBox:
        "flex sm:flex-1 items-center justify-center p-10 bg-nandosorange",
      blueDivBox:
        "flex sm:flex-1 items-center justify-center p-10 bg-nandospink",
      blueImg: "/assets/nandos-2.jpg",
      blueAlt: "Bowl of food and nando's sauce",
      imperativeClass: "pb-10",
      solutionClass: "sm:grid-cols-2",
      solutionGridSpanOne: "lg:col-span-2",
      solutionGridSpanClass: "lg:grid-cols-3",
      solutionTitleOne: "Customer targeting:",
      solutionDescOne:
        "We targeted former international students with memories of Nando’s, creating a community with shared experiences of Nando’s",
      solutionTitleSecond: "Data-led decision-making:",
      solutionDescTwo:
        "We introduced sales and marketing analytics to generate insight and guide decision-making",
      solutionTitleThird: "Brand amplification:",
      solutionDescThree:
        "We worked with key opinion leaders on strategic social channels to amplify and drive traffic to online and offline channels",
      solutionTitleFour: "Channel management:",
      solutionDescFour:
        "We optimised eCommerce store searchability and design, introducing new tracking methods to follow consumer behaviour",
      solutionImg: "/assets/Beijing-Olympics.jpg",
      solutionAlt: "Nando's sauce playing hockey",
      firstQuotePara:
        "“We have seen measurable impact across sales and marketing metrics since starting to work with QIVA. As a brand, we are all about working globally and acting locally. With QIVA as our partner, we feel really confident in our local execution”",
      jobTitle: "Head of International Markets",
      clientPaddingClass: "mb-16",
      nextProject: "/iron-women",
    },
  },
};
