const replaceFontFamilies = (locale) => {
    if (locale === 'cn') {
        document.querySelectorAll('.font-arima').forEach((e) => {
            if (!e.classList.contains('english-font')) {
                e.classList.replace('font-arima', 'font-notoSerif')
            }
        })
        document.querySelectorAll('.font-poppins').forEach((e) => {
            if (!e.classList.contains('english-font')) {
                e.classList.replace('font-poppins', 'font-notoSans')
            }
        })
    } else if (locale === 'en') {
        document
            .querySelectorAll('.font-notoSerif')
            .forEach((e) => e.classList.replace('font-notoSerif', 'font-arima'))
        document
            .querySelectorAll('.font-notoSans')
            .forEach((e) => e.classList.replace('font-notoSans', 'font-poppins'))
    }
}

export default replaceFontFamilies
